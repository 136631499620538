import { cn } from "~/lib/utils";

export const TailwindIndicator = () => {
  if (!import.meta.env.DEV) return null;

  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 z-50 flex items-center justify-center px-2 text-[14px]",
        "bg-gray-800 text-white",
        "sm:bg-blue-700",
        "md:bg-green-600",
        "lg:bg-blue-300",
        "xl:bg-red-400",
        "2xl:bg-purple-300",
      )}
    >
      <div className="block sm:hidden">xs</div>
      <div className="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">
        sm
      </div>
      <div className="hidden md:block lg:hidden xl:hidden 2xl:hidden">md</div>
      <div className="hidden lg:block xl:hidden 2xl:hidden">lg</div>
      <div className="hidden xl:block 2xl:hidden">xl</div>
      <div className="hidden 2xl:block">2xl</div>
    </div>
  );
};
