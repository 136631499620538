import { useEffect, useState } from "react";

export const useInitialLoading = (timeout = 2000) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const hasVisited = localStorage.getItem("visited");

    if (!hasVisited) {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
        localStorage.setItem("visited", "true");
      }, timeout);
    } else {
      setIsLoading(false);
    }
  }, [timeout]);

  return { isLoading };
};
