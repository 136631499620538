import { useLocation } from "@remix-run/react";
import { cn } from "~/lib/utils";
import { Breadcrumb } from "./breadcrumb";

const whitelist = ["/"];

export const SubHeader = () => {
  const location = useLocation();
  if (import.meta.env.VITE_FEATURE_V2 !== "true") return null;
  if (whitelist.includes(location.pathname)) return null;

  return (
    <div
      className={cn(
        "hidden-scrollbar h-10 max-w-[100vw] overflow-x-auto bg-white leading-10",
      )}
    >
      <div className="mx-auto max-w-[1080px] px-6 lg:max-w-[min(calc(100vw-32px),1080px)] lg:px-0">
        <Breadcrumb />
      </div>
    </div>
  );
};
