import { Outlet } from "@remix-run/react";
import { AnimatePresence } from "framer-motion";
import { BreadcrumbItem } from "~/components/breadcrumb";
import { Footer } from "~/components/footer";
import { Header } from "~/components/header";
import { Loading } from "~/components/loading";
import { SubHeader } from "~/components/sub-header";
import { TailwindIndicator } from "~/components/tailwind-indicator";
import { useInitialLoading } from "~/hooks/use-initial-loading";

export const handle = {
  breadcrumb: () => (
    <BreadcrumbItem to="/" variant="top">
      ホーム
    </BreadcrumbItem>
  ),
};

export default function Layout() {
  const { isLoading } = useInitialLoading();
  return (
    <AnimatePresence>
      {isLoading ? (
        <Loading key="loading" />
      ) : (
        <div className="grid min-h-screen grid-rows-[auto_1fr_auto]">
          <Header />
          <SubHeader />
          <TailwindIndicator />
          <main>
            <Outlet />
          </main>
          <Footer />
        </div>
      )}
    </AnimatePresence>
  );
}
